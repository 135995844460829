window.requestAnimFrame = (function(){
  return window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            function( callback ){
              window.setTimeout(callback, 1000 / 60);
            };
})();
  
// main function
function scrollToY(scrollTargetY, speed, easing) {
  // scrollTargetY: the target scrollY property of the window
  // speed: time in pixels per second
  // easing: easing equation to use
  
  var scrollY = window.scrollY || document.documentElement.scrollTop,
    scrollTargetY = scrollTargetY || 0,
    speed = speed || 2000,
    easing = easing || 'easeOutSine',
    currentTime = 0;
  
  // min time .1, max time .8 seconds
  var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));
  
  // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
  var easingEquations = {
    easeOutSine: function (pos) {
      return Math.sin(pos * (Math.PI / 2));
    },
    easeInOutSine: function (pos) {
      return (-0.5 * (Math.cos(Math.PI * pos) - 1));
    },
    easeInOutQuint: function (pos) {
      if ((pos /= 0.5) < 1) {
        return 0.5 * Math.pow(pos, 5);
      }
      return 0.5 * (Math.pow((pos - 2), 5) + 2);
    }
  };
  
  // add animation loop
  function tick() {
    currentTime += 1 / 60;
  
    var p = currentTime / time;
    var t = easingEquations[easing](p);
  
    if (p < 1) {
      requestAnimFrame(tick);
      window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
    } else {
      window.scrollTo(0, scrollTargetY);
    }
  }
  
  // call it once to get started
  tick();
}
  
// scroll it!
// scrollToY(0, 1500, 'easeInOutQuint');
  
var nav_links = document.querySelectorAll('.nav-links li');
  
for(i=0;i<nav_links.length;i++) {
  nav_links[i].addEventListener('click', function(e) {
    console.log(e.target.offsetTop);
    var scrollToElem = e.target.getAttribute('href');
    console.log(document.querySelector(scrollToElem).offsetTop);
    scrollToY(document.querySelector(scrollToElem).offsetTop, 1500, 'easeInOutQuint');
    document.querySelector('.nav-toggle').classList.toggle('opened');
    document.querySelector('.nav-links').classList.toggle('show-nav');        
  }); 
}
  
var logos = document.querySelectorAll('.home-logo');
  
for (let i = 0; i < logos.length; i++) {
  logos[i].addEventListener('click', function() {
    scrollToY(0, 1500, 'easeInOutQuint');
  });
}
  
document.querySelector('.custom-service').addEventListener('click',function() {
  const scroll_offset = document.querySelector('#contact').offsetTop;
  scrollToY(scroll_offset, 1500, 'easeInOutQuint');
});
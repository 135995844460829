import '../css/style.css';
import '../css/preloader.css';
import './scroll';
import Siema from './siema.min';
import PvcDieCollage from '../img/PVC Die Collage.jpg';
import PistonCollage from '../img/Piston collage.png';
import RollersCollage from '../img/Rollers-Collage.png';
import HmDieCollage from '../img/HM Die Collage.png';
import ScrewMin from '../img/Screw-min.jpeg';
import PvcFitting from '../img/PVC Fitting.png';

var PrevSlide, NextSlide;
(function () {

  var slider_index = 0, 
    slider_length = 2;
  
  const modalData = [
    {
      title: 'PVC Die-Head',
      desc: 'We offer hard chromium plating on a wide range of pipe dieheads covering a broad spectrum of diameters and application in the PVC pipe manufacturing. They find their application in the manufacturing of foam core pipes, pressure pipes, mining pipes, highly filled PVC pipes and various other types of PVC pipes for pressure and non-pressure applications. We have our plating tub perfectly fitted to offer good layering over the diehead molds, to ensure their quality for combination with twin-screw extruders.',
      img:  PvcDieCollage
    },
    {
      title: 'Piston Rod & Cylinder',
      desc: 'The surface quality or smoothness of hard-chrome-plated piston rods changes over time. Our electro plating service ensures that the yield strength and the slenderness ratio of the surface remains intact. High quality hydraulic cylinders use high strength piston rods. To ensure top-quality performance and reduce your risk of bulking, our metal additive setup helps in the manufacturing of high quality parts. We are also expanding into alternative forms of electroplating that include nickel, nickel-chrome and nitride plating.',
      img: PistonCollage
    },
    {
      title: 'Roller',
      desc: 'Rollers are intended to have a long service life. Proper care and maintenance will insure less downtime.Here, in our facility we make sure that the equipment remains resistant to mechanical stress and abrasive chemicals by applying thicknesses of between 5 and 500 µm through hard chrome plating. However, layers of up to 1 mm, and does require some subsequent customized machining. Extreme hardness and high resistance to wear and corrosion offered by hard chrome plating allows you to have less maintenance cost.',
      img: RollersCollage
    },
    {
      title: 'HM Die',
      desc: 'Spiral Dies plays an important role in injection molding and other extrusion processing. Numerous end-use products are made with extrusion such as film, tubing, and a variety of profiles. Tailored hard chrome plating also gives you an economic edge.',
      img: HmDieCollage
    },
    {
      title: 'Screw & Barrel',
      desc: 'Chrome plating adds only about 10% to the cost of a typical screw and it provides so many benefits many performance disadvantages. For screw plating, the coating is typically about 0.001 in. thick and does not materially affect the geometry of even the most critical dimensions. Additionally, it has a bond strength of approximately 6000 psi when properly applied, so it almost never comes off due to abrasion. These are mostly used in PVC processing equipment and preferred as double or even triple chrome. Hard-chrome plating is a very cost-effective solution to a lot of problems encountered during the life of the screw.',
      img: ScrewMin
    },
    {
      title: 'PVC Fitting',
      desc: `PVC fittings are connectors that are used for making detachable piece of pipe that connects two
      pipes or tubing. These can be made from different materials. We specialize in plating moulds hat are
      used in manufacturing of plumbing grade connectors and sockets. There is a standard metric called
      Pipe scheduling, which is a nominal system for pipe sizing. That means all parts with the same size in
      their name will be compatible with each other, and all 1″ fittings will be compatible with 1″ pipe.`,
      img: PvcFitting
    }
  ];
  
  document.addEventListener('DOMContentLoaded', function() {
      
    var toggle = document.querySelector('.nav-toggle');
  
    // Add event listener to toggle nav menu for mobiles and tablets  
    toggle.addEventListener('click', function() {
      this.classList.toggle('opened');
      document.querySelector('.nav-links').classList.toggle('show-nav');
    });
  
    // Add event listener to change navbar styles while browsing through the website
    window.addEventListener('scroll', function() {
      if ( window.scrollY > document.querySelector('#hard-chrome-plating').offsetTop - 100 ) {
        document.querySelector('#navbar').setAttribute('style', 'background-color: #000; opacity: 0.8; padding: 7px 0;');
      } else {
        document.querySelector('#navbar').setAttribute('style', 'background-color: transparent; padding: 20px 0');	
      }
    });
  
    // Initialize landing page slilder
    const landing_page_slider = new Siema({
      selector: '.landing-page-slider',
      duration: 500,
      easing: 'ease-out',
      perPage: 1,
      startIndex: 0,
      draggable: true,
      multipleDrag: true,
      threshold: 20,
      loop: false,
      rtl: false,
      onInit: () => {
        setTimeout(() => {
          const first_item = document.querySelectorAll('.slider-item')[0];
          first_item.querySelector('.content-left').classList.add('animate-left');
          first_item.querySelector('.diagonal-line').classList.add('animate-line');
          first_item.querySelector('.content-right').classList.add('animate-right');
        }, 1);
        // setInterval(() => landing_page_slider.next(), 6000);
      },
      onChange: () => {
        let slider_items = document.querySelectorAll('.slider-item');
        for(var i=0;i<slider_items.length;i++) {
          slider_items[i].querySelector('.content-left').classList.remove('animate-left');
          slider_items[i].querySelector('.diagonal-line').classList.remove('animate-line');
          slider_items[i].querySelector('.content-right').classList.remove('animate-right');
        }
        slider_items[landing_page_slider.currentSlide].querySelector('.content-left').classList.add('animate-left');
        slider_items[landing_page_slider.currentSlide].querySelector('.diagonal-line').classList.add('animate-line');
        slider_items[landing_page_slider.currentSlide].querySelector('.content-right').classList.add('animate-right');
      },
    });
  
    PrevSlide = () => {
      slider_index -= 1;
      landing_page_slider.prev(1);
    };
  
    NextSlide = () => {
      slider_index += 1;
      landing_page_slider.next(1);  
    };
  
    document.querySelector('.prev').addEventListener('click', PrevSlide);
    document.querySelector('.next').addEventListener('click', NextSlide);
  
  });
  
  // Auto Scroll
  setInterval( function() {
    ( slider_index >= slider_length ) ? PrevSlide() : NextSlide();
  }, 10000);
  
  window.addEventListener('load', function() {
    document.querySelector('.loading').classList.add('remove-loading');
  });
  
  const services = document.querySelectorAll('.service');
  const service_links = document.querySelectorAll('.open-modal');
  
  for (let i = 0; i < services.length; i++) {
    services[i].addEventListener('click',function(e) {
      e.preventDefault();
      document.querySelector('#services-modal').classList.add('show-modal');
      document.querySelector('.modal-header h3').textContent = modalData[i].title;
      document.querySelector('.services-detail-img').setAttribute('src',modalData[i].img);
      document.querySelector('.services-detail-info p').textContent = modalData[i].desc;
    });
  }
  
  for (let i = 0; i < service_links.length; i++) {
    service_links[i].addEventListener('click',function(e) {
      e.preventDefault();
      document.querySelector('#services-modal').classList.add('show-modal');
      document.querySelector('.modal-header h3').textContent = modalData[i].title;
      document.querySelector('.services-detail-img').setAttribute('src',modalData[i].img);
      document.querySelector('.services-detail-info p').textContent = modalData[i].desc;
    });
  }
  
  document.querySelector('.close-modal-btn').addEventListener('click', function() {
    document.querySelector('#services-modal').classList.remove('show-modal');
  });
  
})();